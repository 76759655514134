import React, {forwardRef, useImperativeHandle, useState} from 'react';
import tw, {styled} from 'twin.macro';
import {Button} from '../../../Button';
import SVGIcon from '../../../SVGIcon';

const NavComponent: React.FC<{
  items: [any];
  sliderRef: any;
  handleSliderButton: (e: any) => void;
  className?: string;
}> = forwardRef((props, ref) => {
  const [sliderIndex, setIndex] = useState(0);

  useImperativeHandle(ref, () => ({
    changeIndex() {
      if (props.sliderRef.current) {
        setIndex(props.sliderRef.current.index);
      }
    },
  }));

  const {items, handleSliderButton, className} = props;

  return (
    <Navigation className={className}>
      <SliderButton
        data-slide-direction="<"
        onClick={handleSliderButton}
        disabled={sliderIndex === 0}
        aria-label="drawer close"
      >
        <SVGIcon icon="drawer_close" />
      </SliderButton>
      <Dots>
        {items.map((item: any, index: number) => {
          return (
            <Dot
              key={`${item.node.id}-navigation`}
              data-slide-direction={`=${index}`}
              onClick={handleSliderButton}
              selected={sliderIndex === index}
            />
          );
        })}
      </Dots>
      <SliderButton
        data-slide-direction=">"
        onClick={handleSliderButton}
        disabled={sliderIndex === items.length - 1}
        aria-label="drawer close"
      >
        <SVGIcon icon="drawer_close" />
      </SliderButton>
    </Navigation>
  );
});

export default NavComponent;

const Navigation = styled.div`
  ${tw`items-center`}

  @media ${props => props.theme.screens.xl} {
    order: 3;
  }
`;

const Dots = styled.div`
  ${tw`flex  mx-4 md:mx-6`}
`;

const Dot = styled.div<{selected: boolean}>`
  ${tw`bg-100 rounded-full cursor-pointer hover:bg-secondary transition-colors`}

  width: 8px;
  height: 8px;

  &:not(:last-child) {
    margin-right: 7px;
  }

  background-color: ${props =>
    props.selected ? props.theme.colors.secondary : ''};

  @media ${props => props.theme.screens.md} {
    &:not(:last-child) {
      margin-right: 11px;
    }
  }
`;

const SliderButton = styled(Button)`
  ${tw`text-white rounded-full p-0 bg-secondary disabled:pointer-events-none disabled:text-100 disabled:bg-transparent disabled:border disabled:border-100 `}
  width: 26px;
  height: 26px;

  svg,
  span {
    ${tw`pointer-events-none`}
  }

  &:first-child {
    transform: rotate(180deg);
  }

  @media ${props => props.theme.screens.md} {
    width: 37px;
    height: 37px;
  }
`;
