import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';

const HeroTablet = ({ children, img }: any) => {
  const { language } = useI18next();

  return (
    <div
      className="relative h-screen w-full mt-20"
      style={{ height: language === 'en' ? 900 : 650 }}
    >
      <GatsbyImage
        image={getImage(img)}
        alt="Hero image"
        className="absolute inset-0 h-full w-full"
      />
      <div className="relative z-10 flex flex-col justify-center w-full h-full px-8 py-12 md:w-3/5">
        {children}
      </div>
    </div>
  );
};

export default HeroTablet;
