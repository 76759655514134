import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

import BookingBanner from '../components/Page/BookingBanner';
import FAQ from '../components/Page/FAQ';

import CleaningTypes from '../components/Page/office-cleaning/CleaningTypes';
import Included from '../components/Page/office-cleaning/Included';

import PageDescription from '../components/Page/office-cleaning/PageDescription';
import Hero from '../components/Page/office-cleaning/PageHero';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SEO from '../components/Seo';

const OfficeCleaning = ({ data }: any) => {
  const { language, t } = useI18next();

  return (
    <Layout>
      <SEO
        title={t('office_cleaning_seo_title')}
        description={t('office_cleaning_seo_description')}
        keywords={t('office_cleaning_seo_keywords', { returnObjects: true })}
        lang={language}
      />
      <Hero />
      <CleaningTypes />
      <PageDescription button />
      <Included />
      <BookingBanner
        heading={`save_time_for_happy_things`}
        color="primary"
        filename="office-cleaning-book-now.png"
        mobileFileName="office-cleaning-book-now-mobile.png"
      />
      <FAQ faqs={data.faq.edges} />
    </Layout>
  );
};

export default OfficeCleaning;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "office_cleaning", "seo_office_cleaning"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "office-cleaning"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
  }
`;
